body {
  margin: 0;
  font-family: "Inter", "GraphikRegular", "Helvetica", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pay-confirm-modal {
  min-width: 300px;
  width: 90%;
  max-width: 400px;
  margin: auto;
}

.left {
  float: left;
}
.right {
  float: right;
}
.center {
  text-align: center;
}

.header {
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  line-height: 1px;
  letter-spacing: -0.005em;
}

.img-profile {
  border-radius: 50%;
  max-width: 120px;
  max-height: 120px;
}

.text-body {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: #000000;
}

.divider {
  border-top: 1px solid #dddddd;
}

.button-confirm {
  /* Button */
  background: #7D7AFF;
  border: 0;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  height: 40px;
  margin: 10px 0;
  cursor: pointer;

  /* Text */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.15px;
  color: #ffffff;
}

.text-cancel {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.15px;
  color: #808084;
  border: 0;
  background: None;
  cursor: pointer;
}
